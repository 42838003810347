<template>
  <div>
    <el-select size="mini" :disabled="isView" @change="handleChange" v-model="year" placeholder="请选择">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'HcYmhfBudgetYear',

  data() {
    return {
      year: null,
      options: []
    }
  },

  props: {
    isView: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.generateYears()
  },

  methods: {
    reset(value) {
      this.year = value
    },
    search() {
      this.$emit('refreshYear', this.year, this.options)
    },
    generateYears() {
      let minYear = 2022 // 设置最小年度
      let years = []

      let nowYear = new Date().getFullYear()
      if (nowYear - 2 <= minYear) {
        years.push({ value: minYear, label: 2022 + '年度' })
        this.year = minYear
      } else {
        this.year = nowYear - 2
        for (let i = minYear; i < nowYear - 1; i++) {
          years.push({ value: i, label: i + '年度' })
        }
      }
      this.options = years
      this.$emit('refreshYear', this.year, this.options)
    },
    handleChange(value) {
      this.year = value
      this.$emit('refreshYear', this.year, this.options)
    }
  }
}
</script>

<style lang="scss" scoped></style>
